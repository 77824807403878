export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36')];

export const server_loads = [];

export const dictionary = {
	"/": [4],
	"/(authenticated)/account/change-password": [5,[2]],
	"/(authenticated)/account/profile": [6,[2]],
	"/(authenticated)/admin/institutions": [7,[2]],
	"/(authenticated)/admin/institutions/create": [9,[2]],
	"/(authenticated)/admin/institutions/[id]": [8,[2]],
	"/(authenticated)/admin/itineraries": [10,[2]],
	"/(authenticated)/admin/itineraries/bookings/detail/[id]": [14,[2]],
	"/(authenticated)/admin/itineraries/bookings/detail/[id]/edit": [15,[2]],
	"/(authenticated)/admin/itineraries/bookings/[id]": [13,[2]],
	"/(authenticated)/admin/itineraries/create": [16,[2]],
	"/(authenticated)/admin/itineraries/[id]": [11,[2]],
	"/(authenticated)/admin/itineraries/[id]/price-changes": [12,[2]],
	"/(authenticated)/admin/live-sessions": [17,[2]],
	"/(authenticated)/admin/live-sessions/create": [19,[2]],
	"/(authenticated)/admin/live-sessions/[id]": [18,[2]],
	"/(authenticated)/admin/payments": [20,[2]],
	"/(authenticated)/admin/profile-questions": [21,[2]],
	"/(authenticated)/admin/profile-questions/[id]": [22,[2]],
	"/(authenticated)/admin/requestingcancellation": [23,[2]],
	"/(authenticated)/admin/users": [24,[2]],
	"/(authenticated)/admin/users/[id]": [25,[2]],
	"/(authenticated)/dashboard": [26,[2]],
	"/(public)/logout": [28,[3]],
	"/(authenticated)/pay-now": [27,[2]],
	"/(public)/public/login": [29,[3]],
	"/(public)/public/programs": [30,[3]],
	"/(public)/public/programs/book/[id]": [31,[3]],
	"/(public)/public/register": [32,[3]],
	"/(public)/public/reset-password-confirm": [34,[3]],
	"/(public)/public/reset-password": [33,[3]],
	"/(public)/public/verify-email": [35,[3]],
	"/(public)/report/program/[id]": [36,[3]]
};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};